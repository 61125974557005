import store from '@/store';

// create getter and setter for the atribute with name 'name'. in the walkthough store.
function walkthrough_to_store(name)
{
    return {
        get() {
            return store.state.walkthrough.form[name];
        },
        set(value){
            // console.log('walkthroug.set:', value);
            store.commit('walkthrough/setWalkthroughValue',{
                key: name,
                value: value,
            });
        }
    };
}

function answer_to_store()
{
    return {
        get() {
            if(this.currentQuestion){
                return store.getters['walkthrough/answerByQuestionId'](this.currentQuestion.id);
            }
            return null;
        },
        set(value){
            store.commit('walkthrough/setAnswer',{
                question_id: this.currentQuestion.id,
                answer_id: value,
            });
        }
    };
}

function error_to_store(field)
{
    return {
        get() {
            return store.getters['walkthrough/error'](field);
        },
        set(value){
            store.commit('walkthrough/setError',{
                field: field,
                error: value,
            });
        }
    };
}

export {
    walkthrough_to_store, 
    answer_to_store, 
    error_to_store, 
    // error_to_store_w_local, 
    // clear_walkthrough_local_storage 
};