<template>
    <div>
        <!-- <div class="fixed-on-top">
            <svg class="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div> -->
        <ImageInput v-model:value="profile_image" v-model:errors="errors.profile_image" :label="$t('profile.image')" />
        <TextAreaInput v-model:value="about" v-model:errors="errors.about" :label="$t('profile.about.title')" :placeholder="$t('profile.about.placeholder')" class="pt-5" />
    </div>
</template>

<script>
import TextAreaInput from "@/components/forms/TextAreaInput";
import ImageInput from "@/components/forms/ImageInput";
import { walkthrough_to_store } from "@/helper/store/walkthrough_to_storage";
import { register_validation } from "@/helper/api/RegistrationHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";


export default {
    name: "ProfileStep",
    methods:{
        validate: async function (){
            setLoading(true);

            const data = {
                profile_image: this.profile_image, 
                about: this.about, 
            }

            const result = await register_validation(data)
                .catch((err) => {
                    messages.error(err.message);
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                });

            if (result.success){
                return true;
            } else {
                this.errors = result.errors;
                return false;
            }
        },
    },
    components:{
        // SelectInput,
        ImageInput,
        TextAreaInput
    }, 
    data(){
        return {
            errors:{
                profile_image: [],
                about: [],
            }
        };
    },
    computed: {
        profile_image: walkthrough_to_store('profile_image'),
        about: walkthrough_to_store('about'),

    },
    async beforeRouteLeave (to, from, next) {        
        if (to.meta.number > from.meta.number && ! await this.validate()){
            next(false);
        } else {
            next();
        }
    },
}
</script>

<style scoped>
    .fixed-on-top{
        position: relative;
        top: -65px;
        /* border: 3px solid #73AD21; */
        margin-left: auto;
        margin-right: auto;
        width: 60px;
    }
</style>
